import request from '@/utils/request'


// 查询客服分组列表
export function listGrouping(query) {
  return request({
    url: '/kf/grouping/list',
    method: 'get',
    params: query
  })
}

// 查询客服分组分页
export function pageGrouping(query) {
  return request({
    url: '/kf/grouping/page',
    method: 'get',
    params: query
  })
}

// 查询客服分组详细
export function getGrouping(data) {
  return request({
    url: '/kf/grouping/detail',
    method: 'get',
    params: data
  })
}

// 新增客服分组
export function addGrouping(data) {
  return request({
    url: '/kf/grouping/add',
    method: 'post',
    data: data
  })
}

// 修改客服分组
export function updateGrouping(data) {
  return request({
    url: '/kf/grouping/edit',
    method: 'post',
    data: data
  })
}

// 删除客服分组
export function delGrouping(data) {
  return request({
    url: '/kf/grouping/delete',
    method: 'post',
    data: data
  })
}
